import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={32}
    height={32}
    fill="#17539C"
    viewBox="0 0 76 76"
    xmlns="http://www.w3.org/2000/svg"
    baseProfile="full"
    xmlSpace="preserve"
    {...props}
  >
    <path
      
      d="m23.75 33.25 11.083 11.083L52.25 22.167l4.75 4.75-22.167 26.916L19 38l4.75-4.75Z"
    />
  </svg>
);
export default SVGComponent;
