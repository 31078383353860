import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={54}
    height={54}
    viewBox="0 0 512 512"
    {...props}
  >
    <g fill="#17539C">
      <path d="M221.5 9.4c-6.9 3.1-7.4 4.3-12.1 32.3-2.4 14.2-4.6 27.2-4.9 28.9-.5 2.9-1.1 3.3-8.8 5.9-4.5 1.5-13.3 5.2-19.6 8.1L164.7 90l-22.6-16.2c-12.4-8.9-24.2-16.6-26.1-17.1-8-2.2-9-1.5-34.4 23.8-25.4 25.4-26.1 26.4-23.9 34.5.5 1.9 8.2 13.7 17.1 26.1L91 163.8l-5.4 11.3c-3 6.3-6.7 15.1-8.2 19.6-2.3 7.1-3.1 8.3-5.3 8.7-1.4.3-14.2 2.4-28.5 4.7-28.1 4.6-30.6 5.5-33.8 11.9-1.6 3.1-1.8 7-1.8 34.8 0 33.5.3 35.5 5.1 39.8 3.2 2.9 2.5 2.7 33.8 7.9 15.1 2.5 27.6 4.7 27.7 4.8s.9 2.7 1.9 5.7c2.4 7.4 10.2 25.7 12.7 29.8l2 3.2-16.3 22.8C66 381.3 58.2 393.1 57.7 395c-2.2 8.1-1.2 9.7 20.6 31.8 11.1 11.2 21.9 21.8 24 23.5 4.5 3.5 11.9 4.7 15.8 2.6 1.3-.7 12.3-8.4 24.5-17.1l22.1-15.8 8.2 4c9.5 4.7 20.8 9.3 26.8 10.9l4.1 1.1 1.1 6.8c.6 3.7 2.9 17.2 5 30 3.3 20.1 4.1 23.6 6.2 25.9 4.7 5.1 6.4 5.3 40.1 5.3 27.8 0 31.7-.2 34.8-1.8 6.5-3.2 7.3-5.7 12-34.4 5.2-32.5 5.1-31.8 6.2-31.8 2 0 20.8-7.5 29.2-11.6l8.9-4.4 22.3 15.9c12.3 8.8 23.3 16.5 24.5 17.2 3.7 1.9 11.2.6 15.6-2.8 2.1-1.7 12.9-12.3 24-23.5 21.8-22.1 22.8-23.7 20.6-31.8-.5-1.9-8.3-13.7-17.2-26.3-16.1-22.5-16.3-22.7-14.7-25.2 2.4-3.6 8.9-18.8 12.1-28 1.5-4.4 2.8-8.1 2.9-8.2.1-.2 12.7-2.3 28-4.8 15.2-2.5 28.7-5 29.9-5.4 2.9-1.2 6.3-4.9 7.6-8.4.7-1.8 1.1-14.3 1.1-34 0-27.7-.2-31.6-1.8-34.7-3.1-6.3-5.9-7.3-30.8-11.4-12.6-2.1-25.4-4.3-28.5-4.8l-5.6-1-2.7-8.1c-1.5-4.5-5.2-13.3-8.2-19.6l-5.4-11.4 16.2-22.6c8.9-12.4 16.6-24.2 17.1-26.1 2.2-8.1 1.5-9.2-23.8-34.5-24.9-24.9-25.8-25.6-33.8-24.1-2 .4-12 6.9-25.6 16.6-12.2 8.8-22.6 16.2-23.1 16.5-.4.3-5.9-1.9-12.1-4.9-6.3-3-15-6.6-19.4-8.1s-8.1-2.8-8.3-3c-.1-.1-2.5-13.2-5.1-28.9-5.3-31.3-5.6-32.2-12.8-35.2-4.6-2-64.4-1.9-68.8 0zM272 125c18.7 2.3 39.5 10 55.1 20.2 48 31.6 70.1 88.9 55.3 143.7-10.4 38.4-38.1 70.6-74.5 86.2-80.3 34.7-171-17.5-182-104.7-7.2-57.2 26.5-114.5 80.2-136.3 22-8.9 43.3-11.8 65.9-9.1z" />
      <path d="M235.7 155.6c-40.7 8.7-71.7 40.3-79.4 80.9-1.9 10.1-1.3 34.1 1.1 43 10.7 39.1 40.9 67.6 79.6 75.1 9.6 1.9 28.4 1.9 38 0 41.4-8 74.4-41.8 81-82.8 2-12.3.8-33.2-2.4-44.3-11-37.1-40.3-64.2-77.9-71.9-9.6-2-30.7-2-40 0zm28.2 29.1c1.3 1.1 3.4 3.2 4.5 4.7 2 2.6 2.1 4.3 2.4 26.6l.3 23.9 23.9.3c22.3.3 24 .4 26.6 2.4 5 3.7 6.9 7.2 6.9 12.4s-1.9 8.7-6.9 12.4c-2.6 2-4 2.1-36.6 2.1s-34-.1-36.6-2.1c-1.5-1.1-3.7-3.3-4.8-4.8-2-2.6-2.1-4.2-2.4-33.9-.2-17.2 0-32.6.3-34.4.7-4 4.9-9.1 9-10.9 3.8-1.8 10-1.1 13.4 1.3z" />
    </g>
  </svg>
);
export default SVGComponent;
