import * as React from "react"
const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} {...props}>
    <defs>
      <filter
        id="a"
        width={26}
        height={26}
        x={0}
        y={0}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur result="blur" stdDeviation={3} />
        <feFlood floodOpacity={0.161} />
        <feComposite in2="blur" operator="in" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g filter="url(#a)">
      <circle
        cx={4}
        cy={4}
        r={4}
        fill="#121212"
        data-name="Elipse 5"
        transform="translate(9 6)"
      />
    </g>
  </svg>
)
export default SvgComponent
