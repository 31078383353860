import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={54}
    height={54}
    viewBox="0 0 512 512"
    {...props}
  >
    <g fill="#17539C">
      <path d="M245 45.2c-42.5 2.7-82.3 26-106.7 62.3-9.9 14.7-19.1 37.6-21.6 53.6l-1.3 8.3-10.5 1.8c-36.7 6-68.6 29.4-84.9 62.3C10.9 252 7.5 266.1 7.6 286c.1 13.6 1.2 21.1 5.1 33.5 5.8 18.3 15.2 33.6 29.2 47.6 20.8 20.7 47.6 32.9 72.2 32.9h7.9v-40.8c0-23.2.5-42.5 1-45 1.6-6.9 4.9-13 10.2-18.7 6.2-6.9 10.4-9.4 19.7-12.1l7.4-2.1 1.8-8.9c2.8-13.5 7.9-25.6 15.8-37.2 4.5-6.6 15.5-17.7 22.6-22.8 11-7.9 26.5-14.3 39.9-16.4 9.5-1.6 31-.8 39 1.4 36.5 10.1 63 38.1 70.4 74.5l1.9 9.4 7.4 2.1c9.3 2.7 13.5 5.2 19.7 12.1 5.3 5.7 8.6 11.8 10.2 18.7.5 2.5 1 21.8 1 45V400h8.3c24.9-.1 53.3-13.3 73.6-34.5 16-16.7 26.7-37.5 31.2-60.5 1.7-8.7 1.7-29.7 0-39-4.1-23-16-45.7-32.4-62.2-17.5-17.5-39.4-28.7-64.1-32.7l-10-1.7-1.2-7.4c-6.8-43.4-37-83.9-76.9-103C295.1 47.8 272 43.4 245 45.2z" />
      <path d="M242 221.4c-24.7 5.2-44.3 22.8-52.6 47.1-2.4 6.9-2.8 10-3.2 23.2l-.4 15.3h-12.5c-8.1 0-13.5.5-15.6 1.4-4.9 2-9.5 7.8-10.2 12.8-.3 2.4-.5 33.5-.3 69.3.3 62.9.4 65.1 2.3 68.4 2.6 4.4 6.2 7 11.1 8.1 5.1 1.2 185.7 1.2 190.8 0 4.9-1.1 8.5-3.7 11.1-8.1 1.9-3.3 2-5.5 2.3-68.4.2-35.8 0-66.9-.3-69.3-.7-5-5.3-10.8-10.2-12.8-2.1-.9-7.5-1.4-15.6-1.4h-12.5l-.4-15.3c-.4-13.2-.8-16.2-3.2-23.2-1.5-4.4-3.9-10-5.3-12.5-9.5-16.4-25.3-28.8-42.9-33.5-8.2-2.2-24.4-2.8-32.4-1.1zm30.3 35.1c7.7 3.8 15.8 12.6 18.8 20.2 1.8 4.7 2.2 8 2.3 17.8l.1 12h-75l.1-12c.1-14.5 2.1-20.8 9-28.4 9.2-10.3 16.4-13.3 29.9-12.8 7.5.3 10 .8 14.8 3.2z" />
    </g>
  </svg>
);
export default SVGComponent;
