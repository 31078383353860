import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={474.004}
    height={476.674}
    viewBox="0 0 474.004 476.674"
    {...props}
  >
    <g
      data-name="Rect\xE1ngulo 12"
      transform="matrix(.995 -.105 .105 .995 0 45.052)"
      fill="none"
      stroke="#cecece"
      strokeLinecap="round"
      strokeWidth={4}
      strokeDasharray="10 10"
    >
      <rect width={431} height={434} rx={36} stroke="none" />
      <rect x={2} y={2} width={427} height={430} rx={34} />
    </g>
  </svg>
);
export default SVGComponent;
