import './App.css';
import React, { useState } from 'react';
import {Navbar, NavbarBrand, NavbarContent, NavbarItem, Button, DropdownItem, DropdownTrigger, Dropdown, DropdownMenu, Avatar} from "@nextui-org/react";
import {DropdownSection, User} from "@nextui-org/react";
import {Accordion, AccordionItem} from "@nextui-org/react";
import {ChevronDown, Lock, Activity, Flash, Server, TagUser, Scale} from "./Assets/Icons.jsx";
import EditDocumentIcon from "./Assets/EditDocumentIcon.jsx";
import UsaIcon from "./Assets/usa";
import Tech from './Assets/Tech';
import Dot from './Assets/Dot';
import { FaAngleDown } from 'react-icons/fa';
import Eficiencia from './Assets/Eficiencia';
import Refresh from './Assets/Refresh';
import Locking from './Assets/Locking';
import MexC from './Assets/MexC';
import WorldBack from './Assets/WorldBack';
import WorldFront from './Assets/WorldFront';
import Check from './Assets/Check';
import SafeLock from './Assets/SafeLock';

function App() {
  const iconClasses = "text-xl text-default-500 pointer-events-none flex-shrink-0";
  const icons = {
    chevron: <ChevronDown fill="currentColor" size={16} />,
    scale: <Scale className="text-warning" fill="currentColor" size={30} />,
    lock: <Lock className="text-danger" fill="currentColor" size={30} />,
    activity: <Activity className="text-secondary" fill="currentColor" size={30} />,
    flash: <Flash className="text-primary" fill="currentColor" size={30} />,
    server: <Server className="text-success" fill="currentColor" size={30} />,
    user: <TagUser className="text-danger" fill="currentColor" size={30} />,
  };

  const [color, setColor] = useState(false)
  const changeColor = () => {

    if(window.scrollY >= 90){
      setColor(true)
    } else {
      setColor(false)
    }

  }

  window.addEventListener('scroll', changeColor)
  return (
    <div className="App bg-white relative">
      
      <Navbar className={color ? 'transition duration-500 bg-transparent' : 'transition duration-500 bg-white'} isBlurred="false" maxWidth='full' height={'7rem'}>
        <NavbarBrand className='relative pl-20'>
          <div className='flex flex-row bg-[#F6F6F6] rounded-full h-14 w-36 justify-center items-center drop-shadow-xl'>
            <h1 className="font-bold text-[35px] pb-1 text-[#00345F]">GSES</h1>
            
          </div>
        </NavbarBrand>
        <NavbarContent className="hidden sm:flex gap-4 w-[65%]" justify="center">
          <div className='flex flex-row bg-[#F6F6F6] rounded-full h-14 w-full justify-center items-center drop-shadow-xl'>
            <Button className='flex flex-row bg-[#00345F] rounded-full h-14 w-36 justify-center items-center drop-shadow-xl transition duration-500 hover:bg-[#17539C]'>
              <h1 className="font-light text-base pb-1 text-white">Inicio</h1>
            </Button>

            <Dropdown>
              <NavbarItem className='pl-8 pr-6 pt-2 h-full bg-[#F6F6F6] rounded-full'>
                <DropdownTrigger isDisabled={true}>
                  <Button
                    disableRipple
                    className="p-0 bg-transparent data-[hover=true]:bg-transparent font-semibold"
                    endContent={icons.chevron}
                    radius="sm"
                    variant="light"
                  >
                    Productos
                  </Button>
                </DropdownTrigger>
              </NavbarItem>
              <DropdownMenu
                aria-label="ACME features"
                className="w-[340px]"
                itemClasses={{
                  base: "gap-4",
                }}
              >
                <DropdownItem
                  key="autoscaling"
                  description="ACME scales apps to meet user demand, automagically, based on load."
                  startContent={icons.scale}
                >
                  REPSE
                </DropdownItem>
                <DropdownItem
                  key="usage_metrics"
                  description="Real-time metrics to debug issues. Slow query added? We’ll show you exactly where."
                  startContent={icons.activity}
                >
                  ICSOE
                </DropdownItem>
                <DropdownItem
                  key="production_ready"
                  description="ACME runs on ACME, join us and others serving requests at web scale."
                  startContent={icons.flash}
                >
                  SISUB
                </DropdownItem>
                <DropdownItem
                  key="99_uptime"
                  description="Applications stay on the grid with high availability and high uptime guarantees."
                  startContent={icons.server}
                >
                  +99% Uptime
                </DropdownItem>
                <DropdownItem
                  key="supreme_support"
                  description="Overcome any challenge with a supporting team ready to respond."
                  startContent={icons.user}
                >
                  +Supreme Support
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>

            <Dropdown>
              <NavbarItem className='pl-8 pr-6 pt-2 h-full bg-[#F6F6F6] rounded-full'>
                <DropdownTrigger>
                  <Button
                    disableRipple
                    className="p-0 bg-transparent data-[hover=true]:bg-transparent font-semibold"
                    endContent={icons.chevron}
                    radius="sm"
                    variant="light"
                  >
                    Servicios
                  </Button>
                </DropdownTrigger>
              </NavbarItem>
              <DropdownMenu
                aria-label="ACME features"
                className="w-[340px]"
                disabledKeys={["supreme_support", "99_uptime"]}
                itemClasses={{
                  base: "gap-4",
                }}
              >
                <DropdownItem
                  key="autoscaling"
                  description="Servicio enfocado en la creación, seguimiento, y administración de contratos de servicios especializados"
                  startContent={icons.server}
                >
                   ASE
                </DropdownItem>
                <DropdownItem
                  key="usage_metrics"
                  description="Solución que facilita la recolección, validación y envío de información requerida por el ICSOE."
                  startContent={icons.flash}
                >
                  RAI
                </DropdownItem>
                <DropdownItem
                  key="production_ready"
                  description="Solución que facilita la recolección, validación y envío de información requerida por el SISUB."
                  startContent={icons.flash}
                >
                  RAS
                </DropdownItem>
                <DropdownItem
                  key="99_uptime"
                  description="Está diseñado para simplificar y automatizar la gestión de tus obligaciones con el REPSE"
                  startContent={icons.lock}
                >
                  RAR
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>

            <NavbarContent className='' justify="end">
              <Button className="hidden lg:flex bg-[#F6F6F6] h-full pl-5 pr-6 w-40 rounded-full font-semibold text-sm transition duration-500 hover:bg-[#D8D8D8]">
                Sobre nosotros
              </Button>
              <Button className="hidden lg:flex bg-[#F6F6F6] h-full pl-10 pr-10 w-40 rounded-full font-semibold text-sm transition duration-500 hover:bg-[#D8D8D8]">
                Contacto
              </Button>
            </NavbarContent>

          </div>
          
          
        </NavbarContent>

        

        <NavbarContent as="div" justify="end" className='pr-20'>
          <div className='flex flex-row bg-[#F6F6F6] rounded-full h-14 w-32 justify-center items-center drop-shadow-xl'>
            <div className="flex gap-3 items-center">
              <Dropdown placement="bottom-end">
                <DropdownTrigger>
                  <Avatar className='drop-shadow-lg' as="button" icon={<MexC />} />
                </DropdownTrigger>
                <DropdownMenu aria-label="Profile Actions" variant="flat" disabledKeys={["idiomas"]}>
                  <DropdownItem key="idiomas" className="h-14 gap-2">
                    <p className="font-semibold">Idiomas</p>
                  </DropdownItem>
                  <DropdownItem key="español" startContent={<EditDocumentIcon className={iconClasses} />}>Español</DropdownItem>
                  <DropdownItem key="english" startContent={<UsaIcon className={iconClasses} />}>English</DropdownItem>
                </DropdownMenu>
              </Dropdown>

              <Dropdown
                radius="sm"
                classNames={{
                  base: "p-0 border-small border-divider bg-background",
                  arrow: "bg-default-200",
                }}
              >
                <DropdownTrigger>
                  <Avatar
                    isBordered
                    as="button"
                    className=' drop-shadow-lg'
                  />
                </DropdownTrigger>
                <DropdownMenu
                  aria-label="Custom item styles"
                  className="p-3"
                  itemClasses={{
                    base: [
                      "rounded-md",
                      "text-default-500",
                      "transition-opacity",
                      "data-[hover=true]:text-foreground",
                      "data-[hover=true]:bg-default-100",
                      "dark:data-[hover=true]:bg-default-50",
                      "data-[selectable=true]:focus:bg-default-50",
                      "data-[pressed=true]:opacity-70",
                      "data-[focus-visible=true]:ring-default-500",
                    ],
                  }}
                >
                  {/*
                  <DropdownSection aria-label="Profile & Actions" showDivider>
                    
                    <DropdownItem
                      className="h-14 gap-2"
                    >
                      <User
                        name="Santiago España"
                        description="@SantiagoEspanna"
                        classNames={{
                          name: "text-default-600",
                          description: "text-default-500",
                        }}
                        avatarProps={{
                          size: "sm",
                          src: "https://avatars.githubusercontent.com/u/95152634?v=4",
                        }}
                      />
                    </DropdownItem>
                    
                    <DropdownItem key="dashboard">
                      Panel
                    </DropdownItem>
                    <DropdownItem key="settings">Configuración</DropdownItem>
                    <DropdownItem
                      key="new_project" 
                    >
                      Mis compras
                    </DropdownItem>
                  </DropdownSection>
                   */}
                   <DropdownSection showDivider>
                    <DropdownItem key="logout" className="text-primary" color="primary">Iniciar sesión</DropdownItem>
                  </DropdownSection> 

                  <DropdownSection aria-label="Preferences" showDivider>
                    <DropdownItem
                      isReadOnly
                      key="theme"
                      className="cursor-default"
                      endContent={
                        <select
                          className="z-10 outline-none w-16 py-0.5 rounded-md text-tiny group-data-[hover=true]:border-default-500 border-small
                          border-default-300 dark:border-default-200 bg-transparent text-default-500"
                          id="theme"
                          name="theme"
                        >
                          
                          <option>Claro</option>
                          <option>Oscuro</option>
                        </select>
                      }
                    >
                      Tema
                    </DropdownItem>
                  </DropdownSection>  

                  <DropdownSection>
                    {/*
                    <DropdownItem key="help_and_feedback">
                      Ayuda
                    </DropdownItem>
                    <DropdownItem key="logout" className="text-danger" color="danger">Cerrar sesión</DropdownItem>
                    */}
                    <DropdownItem key="help_and_feedback">
                      Ayuda
                    </DropdownItem>
                  </DropdownSection> 
                </DropdownMenu>
              </Dropdown>
            </div>
            
          </div>
          
        </NavbarContent>
      </Navbar>
      
      <div className="bg-[url('./Assets/back.png')] bg-center bg-cover">
        <div className='grid grid-rows-6 grid-cols-12 gap-4'>
           
          <div className="h-20"></div> 
          <div className="row-start-2 col-start-2 row-span-6 col-span-4">
            <div className='grid grid-rows-5 grid-cols-4 gap-4'>
              <div className=" rounded-2xl border-5 border-[#707070] bg-[#ffffff] w-96 h-20 ml-10 col-span-3 flex justify-center items-center drop-shadow-lg">
                <h1 className='text-[#121212] text-6xl font-bold drop-shadow-lg'>SOFTWARE</h1>
              </div> 
              <div></div> 
              <div className="col-span-4 flex justify-center items-center">
                <h1 className='text-[#17539C] text-6xl font-bold pr-20 drop-shadow-lg'>ENGINEERING</h1>
              </div> 
              <div className="col-span-3 flex justify-center items-center">
                <h1 className='text-[#121212] text-6xl font-bold pr-28 drop-shadow-lg'>SUPPLY</h1>
              </div> 
              <div className="row-start-6 col-span-4 h-20">
                <div className='text-[#7E7E7E] text-left text-sm font-semibold pl-10 drop-shadow-lg'>
                  <p>En <b className='text-[#284D6B]'>Gaesti SES</b>, llevamos más de dos décadas innovando en software</p>
                  <p>empresarial, complementado con herramientas y productos a la</p>
                  <p>vanguardia tecnológica. ¡Tu éxito es nuestra misión!</p>
                </div>
              </div>
            </div> 
          </div> 

          <div className="row-start-2 col-start-7 row-span-5 col-span-5">
            <div className=' grid grid-rows-5 grid-cols-5 gap-4 h-full'>
              <div className="relative rounded-2xl m-9 bg-[#C0D8F6] row-span-5 col-span-2 drop-shadow-lg flex justify-center items-center">
                <div className='absolute top-5 text-[#121212] text-left text-lg font-semibold pl-1 drop-shadow-lg'>
                  <p>Desarrollo de</p>
                  <p>Aplicaciones con GSES</p>
                  <p className=' pt-4 text-sm text-[#575757] flex justify-center items-center'><Dot className="pt-1"/> Aplicaciones Nativas</p>
                  <p className=' pt-4 pr-5 text-sm text-[#575757] flex justify-center items-center'><Dot className="pt-1"/> Aplicaciones Web</p>
                </div>
                <Tech className=" mt-36 drop-shadow-lg "/>
              </div> 
              <div className="relative row-span-5 col-span-3">
                <WorldBack className="absolute -top-10 -left-6 scale-90 drop-shadow-lg "/>
                <div className=' rounded-3xl mt-9 ml-10 h-[85%] w-[84%] bg-[#F6F6F6] drop-shadow-lg'></div>
                <WorldFront className="absolute -bottom-16 -left-20 scale-75 drop-shadow-lg "/>
              </div> 
            </div> 
          </div> 
          <div className="h-20"></div>
          
          <div className=" rounded-2xl mx-9 bg-[#F6F6F6] row-start-7 col-start-7 row-span-2 col-span-5 drop-shadow-lg h-40">
            <div className='text-left drop-shadow-lg'>
              <p className='text-[#121212] font-semibold pt-8 pl-10 drop-shadow-lg'>Suite de Asistencia REPSE</p>
              <p className='text-[#7E7E7E] font-semibold pt-3 pl-10 drop-shadow-lg'>Nuestra solución integral diseñada para simplificar y agilizar el proceso de</p>
              <p className='text-[#7E7E7E] font-semibold pt-1 pl-10 drop-shadow-lg'>declaración y cumplimiento de los requisitos del REPSE, ICSOE y SISUB.</p>
            </div>  
          </div> 
            
          <div className="col-start-2 col-span-2 pr-5">
            <Button className='bg-[#00345F] text-white font-semibold w-52 h-14'>
              Ver Herramientas
            </Button>
          </div> 
          <div className="h-20"></div> 
          <div className="col-span-full h-5"></div> 
          <div className="bg-[#00345F] col-span-full h-20 flex justify-center items-center"><FaAngleDown className='text-white scale-150'/></div> 
        </div>
      </div>



      <div className='bg-white flex justify-center items-center h-[50rem]'>
        <div className=' h-[44rem] rounded-3xl bg-[#F6F6F6] w-[75%] drop-shadow-lg'>
          <h1 className=' text-4xl font-bold drop-shadow-lg pt-8'>Suite de Asistencia REPSE</h1>
          <p className=' text-[#7E7E7E] font-light drop-shadow-lg mt-5'>¿Por qué la Suite de Asistencia REPSE es tu mejor elección para garantizar el cumplimiento regulatorio?</p>

          <div className=' grid grid-rows-1 grid-cols-3 h-[33rem]'>
            
            <div className='relative rounded-3xl m-10 bg-white drop-shadow-lg'>
              <div className=' rounded-2xl h-20 w-20 ml-10 mt-10 bg-[#EBF4FF] flex justify-center items-center'>
                <Eficiencia className="drop-shadow-lg "/>
              </div>
              <h1 className=' text-2xl text-left font-semibold drop-shadow-lg mt-6 ml-10'>Eficiencia y Automatización</h1>
              <div className='absolute left-10 w-80 mt-10'>
                <div className=' text-left text-[#7E7E7E] font-semibold'>
                  <p>Transforma tus procesos manuales en </p>
                  <p>operaciones automatizadas. Nuestra Suite</p>
                  <p>utiliza tecnología para minimizar la</p>
                  <p>intervención humana, acelerar tareas y</p>
                  <p>garantizar la precisión y conformidad en</p>
                  <p>cada paso.</p>
                </div>
              </div>
            </div>
            {/*       */} 
            <div className='relative rounded-3xl m-10 bg-white drop-shadow-lg'>
              <div className=' rounded-2xl h-20 w-20 ml-10 mt-10 bg-[#EBF4FF] flex justify-center items-center'>
                <Refresh className="drop-shadow-lg "/>
              </div>
              <h1 className=' text-2xl text-left font-semibold drop-shadow-lg mt-6 ml-10'>Actualizaciones Constantes</h1>
              <div className='absolute left-10 w-80 mt-10'>
                <div className=' text-left text-[#7E7E7E] font-semibold'>
                  <p>En un entorno regulatorio en constante</p>
                  <p>cambio, nuestra Suite se mantiene al día</p>
                  <p>con las últimas reformas y regulaciones.</p>
                  <p>No sólo te informamos de los cambios,</p>
                  <p>sino que también adaptamos</p>
                  <p>las herramientas para cumplir con ellos.</p>
                </div>
              </div>
            </div>
            
            <div className='relative rounded-3xl m-10 bg-white drop-shadow-lg'>
              <div className=' rounded-2xl h-20 w-20 ml-10 mt-10 bg-[#EBF4FF] flex justify-center items-center'>
                <Locking className="drop-shadow-lg "/>
              </div>
              <h1 className=' text-2xl text-left font-semibold drop-shadow-lg mt-3 ml-10'>Autonomía y</h1>
              <h1 className=' text-2xl text-left font-semibold drop-shadow-lg ml-10'>Almacenamiento Seguro</h1>
              <div className='absolute left-10 w-80 mt-5'>
                <div className=' text-left text-[#7E7E7E] font-semibold'>
                  <p>Priorizamos tu independencia y la</p>
                  <p>protección de tus datos. Con nuestra</p>
                  <p>plataforma, tienes el control total sobre</p>
                  <p>tu información, mientras te ofrecemos un</p>
                  <p>almacenamiento robusto y encriptado.</p>
                  <p>Olvídate de terceros o sistemas vulnerables.</p>
                </div>
              </div>
            </div>
             
          </div>
        </div>
      </div>

      <div className=' w-[75%] h-[35rem] mt-5 mb-10 mx-auto flex justify-center items-center'>
        <div className='mx-auto h-auto w-[59rem]'>
          <h1 className=' text-3xl font-bold drop-shadow-lg pb-2 text-left'>Descubre Nuestros Módulos</h1>
          <p className='text-left text-[#7E7E7E] font-light drop-shadow-lg mt-1 mb-5'>La Suite de Asistencia REPSE no es solo una herramienta, es tu aliado
          estratégico en la gestión de servicios.</p>
          <Accordion>
            <AccordionItem key="1" aria-label="Accordion 1" title="Administrador de Servicios Especializados (ASE)">{
                <div className='w-[90%]'>
                  <p className='text-left'>Una solución integral para administrar y rastrear todos tus servicios. </p>
                  <p className='text-left'>Consolida y gestiona todos los elementos esenciales de tus servicios en una sola herramienta.
                  Almacena la información de tus contratantes y empleados, programa tus servicios en un calendario integrado y archiva
                  todos los documentos necesarios. Una base de datos organizada y fácil de usar que simplifica la preparación y ejecución de cada contrato.</p>
                </div>
              }
            </AccordionItem>
            <AccordionItem key="2" aria-label="Accordion 2" title="Reporte Automatizado para ICSOE (RAI)">{
                <div className='w-[90%]'>
                  <p className='text-left'>Simplifica y acelera el proceso de entrega de información al ICSOE.</p>
                  <p className='text-left'>Elimina la complejidad de cumplir con los requisitos del ICSOE. Solo ingresa los datos y
                  documentos relevantes una vez en nuestra base de datos y deja que la herramienta genere y prepare los reportes necesarios.
                  Asegura la precisión y cumple a tiempo, sin complicaciones.</p>
                </div>
              }
            </AccordionItem>
            <AccordionItem key="3" aria-label="Accordion 3" title="Reporte Automatizado para SISUB (RAS)">{
                <div className='w-[90%]'>
                  <p className='text-left'>Haz más eficiente la entrega de información al SISUB.</p>
                  <p className='text-left'>Convierte el proceso de reportar al SISUB en una tarea sencilla y automatizada. Nuestra herramienta
                  recopila la información almacenada en tu base de datos y la transforma en reportes estructurados y listos para ser enviados.
                  Simplifica tus obligaciones y garantiza el cumplimiento</p>
                </div>
              }
            </AccordionItem>
            <AccordionItem key="4" aria-label="Accordion 3" title="Reporte Automatizado para REPSE (RAR)">{
                <div className='w-[90%]'>
                  <p className='text-left'>Mantente al día con los requerimientos del REPSE.</p>
                  <p className='text-left'>Optimiza tu proceso de reporte al REPSE con nuestra herramienta especializada. Introduce tus datos
                  y documentos clave en la base de datos y deja que nuestro sistema prepare de forma automatizada las declaraciones conforme a
                  las exigencias del REPSE. Asegura tu cumplimiento, ahorra tiempo y evita errores comunes.</p>
                </div>
              }
            </AccordionItem>
          </Accordion>
        </div>
      </div>

      <div className='h-[53rem] bg-[#183764]'>
        <h1 className=' text-4xl text-white pt-12 font-bold drop-shadow-lg '>Elige Tu Módulo</h1>
        <div className=' h-[38rem] w-[85%] mx-auto mt-8'>
          <div className='grid grid-rows-1 grid-cols-5 h-[35rem] pt-10'>
              
            <div className='group rounded-3xl bg-white transition duration-500 hover:bg-[#00345F] hover:scale-[105%] hover:border-3 hover:border-white m-5 drop-shadow-lg'>
              <div className='h-[75%] mt-8 mx-5'>
                <h1 className=' text-4xl text-left text-black font-bold transition duration-500 group-hover:text-white'>ASE</h1>
                <p className='text-xs text-left mt-2 transition duration-500 group-hover:text-white'>Administrador de Servicios Especializados</p>
                <div className='h-20 mt-5 w-36 mx-auto'>
                  <div className='flex flex-row justify-center items-center'>
                    <p className='text-2xl font-base pr-1 transition duration-500 group-hover:text-white'>$</p>
                    <p className='text-[#121212] text-5xl font-semibold transition duration-500 group-hover:text-white'>150</p>
                    <div className='-space-y-2'>
                      <p className='text-[#888888] transition duration-500 group-hover:text-[#C1C1C1] text-sm font-light pt-5'>MXN</p>
                      <p className='text-[#888888] transition duration-500 group-hover:text-[#C1C1C1] text-sm font-light'>/Mes</p>
                    </div>
                  </div>
                </div>
                <div className='h-48'>
                  <div className=' flex flex-row items-center'>
                    <Check className='fill-[#17539C] transition duration-500 group-hover:fill-white'/>
                    <p className='text-left text-sm font-normal transition duration-500 group-hover:text-white'>Registro detallado de servicios</p>
                  </div>
                  <div className=' flex flex-row items-center'>
                    <Check className='fill-[#17539C] transition duration-500 group-hover:fill-white'/>
                    <p className='text-left text-sm font-normal transition duration-500 group-hover:text-white'>Base de datos de contratantes</p>
                  </div>
                  <div className=' flex flex-row items-center'>
                    <Check className='fill-[#17539C] transition duration-500 group-hover:fill-white'/>
                    <p className='text-left text-sm font-normal transition duration-500 group-hover:text-white'>Registro de trabajadores</p>
                  </div>
                  <div className=' flex flex-row items-center'>
                    <Check className='fill-[#17539C] transition duration-500 group-hover:fill-white'/>
                    <p className='text-left text-sm font-normal transition duration-500 group-hover:text-white'>Calendario de servicios</p>
                  </div>
                  <div className=' flex flex-row items-center'>
                    <Check className='fill-[#17539C] transition duration-500 group-hover:fill-white'/>
                    <p className='text-left text-sm font-normal transition duration-500 group-hover:text-white'>Almacenamiento de documentos</p>
                  </div>
                  <div className=' flex flex-row items-center'>
                    <Check className='fill-[#17539C] transition duration-500 group-hover:fill-white'/>
                    <p className='text-left text-sm font-normal transition duration-500 group-hover:text-white'>Generación de reportes</p>
                  </div>
                </div>
              </div>
              <Button className='rounded-full bg-[#17539C] w-40 h-14 mt-5 text-white font-semibold drop-shadow-lg'>Acceder</Button>
            </div>

            <div className='group rounded-3xl bg-white transition duration-500 hover:bg-[#00345F] hover:scale-[105%] hover:border-3 hover:border-white m-5 drop-shadow-lg'>
              <div className='h-[75%] mt-8 mx-5'>
                <h1 className=' text-4xl text-left text-black font-bold transition duration-500 group-hover:text-white'>RAI</h1>
                <p className='text-xs text-left mt-2 transition duration-500 group-hover:text-white'>Reporte Automatizado para ICSOE</p>
                <div className='h-20 mt-5 w-36 mx-auto'>
                  <div className='flex flex-row justify-center items-center'>
                    <p className='text-2xl font-base pr-1 transition duration-500 group-hover:text-white'>$</p>
                    <p className='text-[#121212] text-5xl font-semibold transition duration-500 group-hover:text-white'>430</p>
                    <div className='-space-y-2'>
                      <p className='text-[#888888] transition duration-500 group-hover:text-[#C1C1C1] text-sm font-light pt-5'>MXN</p>
                      <p className='text-[#888888] transition duration-500 group-hover:text-[#C1C1C1] text-sm font-light'>/Mes</p>
                    </div>
                  </div>
                </div>
                <div className='h-48'>
                  <div className=' flex flex-row items-center'>
                    <Check className='fill-[#17539C] transition duration-500 group-hover:fill-white'/>
                    <p className='text-left text-sm font-normal transition duration-500 group-hover:text-white'>Interfaz amigable</p>
                  </div>
                  <div className=' flex flex-row items-center'>
                    <Check className='fill-[#17539C] transition duration-500 group-hover:fill-white'/>
                    <p className='text-left text-sm font-normal transition duration-500 group-hover:text-white'>Base de datos encriptada</p>
                  </div>
                  <div className=' flex flex-row items-center'>
                    <Check className='fill-[#17539C] transition duration-500 group-hover:fill-white'/>
                    <p className='text-left text-sm font-normal transition duration-500 group-hover:text-white'>Sincronización de información</p>
                  </div>
                  <div className=' flex flex-row items-center'>
                    <Check className='fill-[#17539C] transition duration-500 group-hover:fill-white'/>
                    <p className='text-left text-sm font-normal transition duration-500 group-hover:text-white'>Acceso Rápido a la Información</p>
                  </div>
                  <div className=' flex flex-row items-center'>
                    <Check className='fill-[#17539C] transition duration-500 group-hover:fill-white'/>
                    <p className='text-left text-sm font-normal transition duration-500 group-hover:text-white'>Almacenamiento de documentos</p>
                  </div>
                  <div className=' flex flex-row items-center'>
                    <Check className='fill-[#17539C] transition duration-500 group-hover:fill-white'/>
                    <p className='text-left text-sm font-normal transition duration-500 group-hover:text-white'>Declaración Autogenerada</p>
                  </div>
                </div>
              </div>
              <Button className='rounded-full bg-[#17539C] w-40 h-14 mt-5 text-white font-semibold drop-shadow-lg'>Acceder</Button>
            </div>

            <div className='group rounded-3xl bg-white transition duration-500 hover:bg-[#00345F] hover:scale-[105%] hover:border-3 hover:border-white m-5 drop-shadow-lg'>
              <div className='h-[75%] mt-8 mx-5'>
                <h1 className=' text-4xl text-left text-black font-bold transition duration-500 group-hover:text-white'>RAS</h1>
                <p className='text-xs text-left mt-2 transition duration-500 group-hover:text-white'>Reporte Automatizado para SISUB</p>
                <div className='h-20 mt-5 w-36 mx-auto'>
                  <div className='flex flex-row justify-center items-center'>
                    <p className='text-2xl font-base pr-1 transition duration-500 group-hover:text-white'>$</p>
                    <p className='text-[#121212] text-5xl font-semibold transition duration-500 group-hover:text-white'>430</p>
                    <div className='-space-y-2'>
                      <p className='text-[#888888] transition duration-500 group-hover:text-[#C1C1C1] text-sm font-light pt-5'>MXN</p>
                      <p className='text-[#888888] transition duration-500 group-hover:text-[#C1C1C1] text-sm font-light'>/Mes</p>
                    </div>
                  </div>
                </div>
                <div className='h-48'>
                  <div className=' flex flex-row items-center'>
                    <Check className='fill-[#17539C] transition duration-500 group-hover:fill-white'/>
                    <p className='text-left text-sm font-normal transition duration-500 group-hover:text-white'>Interfaz amigable</p>
                  </div>
                  <div className=' flex flex-row items-center'>
                    <Check className='fill-[#17539C] transition duration-500 group-hover:fill-white'/>
                    <p className='text-left text-sm font-normal transition duration-500 group-hover:text-white'>Base de datos encriptada</p>
                  </div>
                  <div className=' flex flex-row items-center'>
                    <Check className='fill-[#17539C] transition duration-500 group-hover:fill-white'/>
                    <p className='text-left text-sm font-normal transition duration-500 group-hover:text-white'>Sincronización de información</p>
                  </div>
                  <div className=' flex flex-row items-center'>
                    <Check className='fill-[#17539C] transition duration-500 group-hover:fill-white'/>
                    <p className='text-left text-sm font-normal transition duration-500 group-hover:text-white'>Acceso Rápido a la Información</p>
                  </div>
                  <div className=' flex flex-row items-center'>
                    <Check className='fill-[#17539C] transition duration-500 group-hover:fill-white'/>
                    <p className='text-left text-sm font-normal transition duration-500 group-hover:text-white'>Almacenamiento de documentos</p>
                  </div>
                  <div className=' flex flex-row items-center'>
                    <Check className='fill-[#17539C] transition duration-500 group-hover:fill-white'/>
                    <p className='text-left text-sm font-normal transition duration-500 group-hover:text-white'>Autogeneración de entregables</p>
                  </div>
                </div>
              </div>
              <Button className='rounded-full bg-[#17539C] w-40 h-14 mt-5 text-white font-semibold drop-shadow-lg'>Acceder</Button>
            </div>

            <div className='relative m-5'>
              <div className='rounded-3xl select-none blur bg-white h-[100%] drop-shadow-lg'>
                <div className='h-[75%] mx-5'>
                  <h1 className=' text-4xl text-left text-black font-bold pt-8 transition duration-500 group-hover:text-white'>RAR</h1>
                  <p className='text-xs text-left mt-2 transition duration-500 group-hover:text-white'>Reporte Automatizado para REPSE</p>
                  <div className='h-20 mt-5 w-36 mx-auto'>
                    <div className='flex flex-row justify-center items-center'>
                      <p className='text-2xl font-base pr-1 transition duration-500 group-hover:text-white'>$</p>
                      <p className='text-[#121212] text-5xl font-semibold transition duration-500 group-hover:text-white'>1,000</p>
                      <div className='-space-y-2'>
                        <p className='text-[#888888] transition duration-500 group-hover:text-[#C1C1C1] text-sm font-light pt-5'>MXN</p>
                        <p className='text-[#888888] transition duration-500 group-hover:text-[#C1C1C1] text-sm font-light'>/Mes</p>
                      </div>
                    </div>
                  </div>
                  <div className='h-48'>
                  <div className=' flex flex-row items-center'>
                    <Check className='fill-[#17539C] transition duration-500 group-hover:fill-white'/>
                    <p className='text-left text-sm font-normal transition duration-500 group-hover:text-white'>Interfaz amigable</p>
                  </div>
                  <div className=' flex flex-row items-center'>
                    <Check className='fill-[#17539C] transition duration-500 group-hover:fill-white'/>
                    <p className='text-left text-sm font-normal transition duration-500 group-hover:text-white'>Base de datos encriptada</p>
                  </div>
                  <div className=' flex flex-row items-center'>
                    <Check className='fill-[#17539C] transition duration-500 group-hover:fill-white'/>
                    <p className='text-left text-sm font-normal transition duration-500 group-hover:text-white'>Sincronización de información</p>
                  </div>
                  <div className=' flex flex-row items-center'>
                    <Check className='fill-[#17539C] transition duration-500 group-hover:fill-white'/>
                    <p className='text-left text-sm font-normal transition duration-500 group-hover:text-white'>Acceso Rápido a la Información</p>
                  </div>
                  <div className=' flex flex-row items-center'>
                    <Check className='fill-[#17539C] transition duration-500 group-hover:fill-white'/>
                    <p className='text-left text-sm font-normal transition duration-500 group-hover:text-white'>Almacenamiento de documentos</p>
                  </div>
                  <div className=' flex flex-row items-center'>
                    <Check className='fill-[#17539C] transition duration-500 group-hover:fill-white'/>
                    <p className='text-left text-sm font-normal transition duration-500 group-hover:text-white'>Prepara archivos</p>
                  </div>
                </div>
                </div>
                <Button isDisabled className='rounded-full bg-[#17539C] w-40 h-14 mt-12 text-white font-semibold drop-shadow-lg'>Acceder</Button>
              </div>
              <SafeLock className='absolute left-28 bottom-56'/>
            </div>

            <div className='relative m-5'>
              <div className='rounded-3xl select-none blur bg-white h-[100%] drop-shadow-lg'>
                <div className='h-[75%] mx-5'>
                  <h1 className=' text-4xl text-left text-black font-bold pt-8 transition duration-500 group-hover:text-white'>PIR</h1>
                  <p className='text-xs text-left mt-2 transition duration-500 group-hover:text-white'>Paquete Integral REP-SES</p>
                  <div className='h-20 mt-5 w-36 mx-auto'>
                    <div className='flex flex-row justify-center items-center'>
                      <p className='text-2xl font-base pr-1 transition duration-500 group-hover:text-white'>$</p>
                      <p className='text-[#121212] text-5xl font-semibold transition duration-500 group-hover:text-white'>1,800</p>
                      <div className='-space-y-2'>
                        <p className='text-[#888888] transition duration-500 group-hover:text-[#C1C1C1] text-sm font-light pt-5'>MXN</p>
                        <p className='text-[#888888] transition duration-500 group-hover:text-[#C1C1C1] text-sm font-light'>/Mes</p>
                      </div>
                    </div>
                  </div>
                  <div className='h-48'>
                    <div className=' flex flex-row items-center'>
                      <Check className='fill-[#17539C] transition duration-500 group-hover:fill-white'/>
                      <p className='text-left text-xs font-normal transition duration-500 group-hover:text-white'>Administrador de Servicios Especializados</p>
                    </div>
                    <div className=' flex flex-row items-center'>
                      <Check className='fill-[#17539C] transition duration-500 group-hover:fill-white'/>
                      <p className='text-left text-xs font-normal transition duration-500 group-hover:text-white'>Reporte Automatizado para ICSOE</p>
                    </div>
                    <div className=' flex flex-row items-center'>
                      <Check className='fill-[#17539C] transition duration-500 group-hover:fill-white'/>
                      <p className='text-left text-xs font-normal transition duration-500 group-hover:text-white'>Reporte Automatizado para SISUB</p>
                    </div>
                    <div className=' flex flex-row items-center'>
                      <Check className='fill-[#17539C] transition duration-500 group-hover:fill-white'/>
                      <p className='text-left text-xs font-normal transition duration-500 group-hover:text-white'>Reporte Automatizado para REPSE</p>
                    </div>
                  </div>
                </div>
                <Button isDisabled className='rounded-full bg-[#17539C] w-40 h-14 mt-12 text-white font-semibold drop-shadow-lg'>Acceder</Button>
              </div>
              <SafeLock className='absolute left-28 bottom-56'/>
            </div>
            

          </div>
        </div>

        <p className=' text-[#d6d6d6]'>Los precios indicados son tarifas base. Estas pueden incrementar dependiendo de la cantidad de información 
        que se desee almacenar. El plan base incluye 50 GB de almacenamiento inicial.</p>
        <p className=' text-[#d6d6d6]'>Se te informará con anticipación en caso de requerir espacio adicional y antes de cualquier cobro adicional.</p>
      </div>

      <div className='h-[30rem]'></div>

    </div>
  );
}

export default App;