import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={54}
    height={54}
    viewBox="0 0 512 512"
    {...props}
  >
    <path
      d="m65.8 204.7-.3 153.8-20.3.5-20.3.5 6.5 10c3.5 5.5 9.8 15.2 13.9 21.5s16.2 24.8 26.7 41c10.6 16.2 19.8 30.1 20.4 30.8 1.2 1.3 5.5-5 54.4-80.5l15.1-23.3H121V107h228l-.2-27.8-.3-27.7-141.2-.3L66 51l-.2 153.7zm318.5-99.5-34.1 52.3 20.4.3 20.4.2v252H163v55h283l.2-153.3.3-153.2 20.3-.3c11.2-.1 20.2-.6 20-1.1-.7-1.9-67.3-104-67.9-104.1-.3 0-15.9 23.5-34.6 52.2z"
      fill="#17539C"
    />
  </svg>
);
export default SVGComponent;
